import frontPagePicture from './BusinessBillede.jpg';
import './App.css';
import Project from './components/Project';
import cPlusPlusPic from './svg_package/c++.png'
import cssPic from './svg_package/css.png'
import htmlPic from './svg_package/html.png'
import javaPic from './svg_package/java.png'
import jsPic from './svg_package/js.png'
import nodejsPic from './svg_package/nodejs.png'
import pythonPic from './svg_package/python.png'
import reactPic from './svg_package/React.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='PicAndResume'>
          <div className='NameAndResume'>
            <p>
              Gustav Balslev Nielsen
            </p>
            <div className='Abilities'>
              <img src={cPlusPlusPic} className="ability" alt="abilityPic" width={25} />
              <img src={cssPic} className="ability" alt="abilityPic" width={25} />
              <img src={htmlPic} className="ability" alt="abilityPic" width={35} />
              <img src={javaPic} className="ability" alt="abilityPic" width={25} />
              <img src={jsPic} className="ability" alt="abilityPic" width={25} />
              <img src={nodejsPic} className="ability" alt="abilityPic" width={50} />
              <img src={pythonPic} className="ability" alt="abilityPic" width={25} />
              <img src={reactPic} className="ability" alt="abilityPic" width={25} />
            </div>
            <h6 className='subtitle'>Softwareudvikler</h6>
            <a
              className="Resume-link"
              href="https://drive.google.com/file/d/1CFXmgEj8hX-_TW6nOu84HJky9o3kz5-n/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className='button'>Resume.pdf</button>
            </a>
          </div>
          <img src={frontPagePicture} className="FrontPagePicture" alt="logo" />
        </div>
        <h6>Recent projects</h6>
        <div className='ProjectSection'>
          <div className='Project'><Project title="Grade guesser" subtitle="Neural network built from scratch in Python." git="https://github.com/gbalslevn/GradeGuesser" website="" /></div>
          {/*<div className='Project'><Project title="Realtime Chat" subtitle="node.js, Socket.io and React." git="https://github.com/gbalslevn/Realtime-Chat.git" website="https://realtimechat-yp8b.onrender.com/" /></div>*/}
          <div className='Project'><Project title="Hotstone" subtitle="Java - Focus on different design patterns and principles." git="https://github.com/gbalslevn/Hotstone.git" website="" /></div>
          <div className='Project'><Project title="Multiplayer wager game" subtitle="HTML, CSS, Javascript, Firebase auth (login functionality), Firebase Realtime Database, Socket.io and node.js" git="https://github.com/gbalslevn/Multiplayer-wager-game.git" website="https://wager-game.onrender.com" /></div>
          <div className='Project'><Project title="Smil" subtitle="React Native, Firebase, Approved by Apple and launched to App Store" git="" website="https://apps.apple.com/dk/app/smil/id6448200374?l=da" /></div>
          <div className='Project'><Project title="Transcribe service" subtitle="AWS services (S3, Lambda, CloudWatch, Transcribe), Ruby, Go, Website" git="https://github.com/gbalslevn/transcribe" website="https://transcribe-audio-to-danish.onrender.com/" /></div>
        </div>
        <p className='contact'>gbalslevn@gmail.com</p>
        <p className='contact'>53 81 58 02</p>
      </header>
    </div>
  );
}

export default App;
