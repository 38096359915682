import './Project.css';
import gitLogo from '../components/github_logo.svg';
import webLogo from '../components/webLogo.svg';

function Project({ title, subtitle, website, git }) {
  return (
      <button className='projectBox'>
        <h3>{title}</h3>
        <h5>{subtitle}</h5>
        <div className='logoLinks'>
          {git && <a href={git} target="_blank"><img src={gitLogo} className="gitLogo" alt="githubLogo" width={18} /></a>}
          {/* If the prop website is empty the websitelink will not be generated */}
          {website && <a href={website} target="_blank" className='websiteLink'><img src={webLogo} className="webLogo" alt="websiteLogo" width={18} /></a>}
        </div>
      </button>
  );
}

export default Project;